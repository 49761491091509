#login-error-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

#login-error-container > i {
  font-size: 32px;
  flex: 0 1 auto;
  margin-right: 20px;
}

#login-error-container > div ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
